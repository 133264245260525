@import '../../../../../../app/assets/stylesheets/shared/variables/colors'
@import '../../../../../../app/assets/stylesheets/shared/utilities/breakpoints'
@import '../../../../../../app/assets/stylesheets/shared/variables/fonts'
$solar-color: $jaune
$wind-color: $vert
$water-color: $bleu
$biomass-color: $brun
$citizen-color: $rouge

.bloc.bloc-carte-producteurs
  .producer-map
    // On mobile, map covers most of the screen but leaves some space to scroll
    height: 80vh

    .leaflet-popup

      // coloured bottom border
      .leaflet-popup-content-wrapper
        border-bottom: 5px solid
      &.solar-energy .leaflet-popup-content-wrapper
        border-color: $solar-color
      &.wind-energy .leaflet-popup-content-wrapper
        border-color: $wind-color
      &.water-energy .leaflet-popup-content-wrapper
        border-color: $water-color
      &.biomass-energy .leaflet-popup-content-wrapper
        border-color: $biomass-color
      &.citizensolar-energy, &.citizenwind-energy, &.citizenwater-energy, &.citizenbiomass-energy
        .leaflet-popup-content-wrapper
          border-color: $citizen-color

      .leaflet-popup-content
        margin: spacer(2)
        font-size: 14px

        i.icon, .popup-citizen-icon
          float: right

        i.icon
          @extend .h5-size !optional

        .popup-citizen-icon
          height: 40px
          width: 40px
          margin: 0 0 5px 5px

        img
          width: auto
          max-width: 100%
          max-height: 200px
          // center the image
          display: block
          margin: 1em auto
        hr
          margin: spacer(1) 0
        .description, .additional_info_url
          margin: 0.5em 0
          color: $gris-fonce
          font-style: normal
          font-weight: bold

    .leaflet-control-layers
      // Don't display controls on mobile
      display: none

      @include themify($schemes)
        color: schemed('primary')
        background-color: schemed('secondary')
      border: none
      padding: spacer(3)
      border-radius: 0

      .leaflet-control-layers-overlays
        label
          font-family: $title-font
          text-transform: uppercase
          font-size: 18px
          margin-bottom: 0
          color: $noir

          &:not(:last-child)
            margin-bottom: spacer(2)

          &:last-of-type
            .leaflet-control-layers-selector
              display: none

          input[type=checkbox]
            margin-right: spacer(1)

        .citizen-legend-picto
          -moz-border-radius: 9px/9px
          -webkit-border-radius: 9px 9px
          border-radius: 9px/9px
          border: solid 3px $citizen-color
          height: 9px
          width: 9px
          margin-right: 13px
          display: inline-block

    // override z-index values for leaflet since the initially
    // are between 100 and 600, so the map comes over the navbar
    .leaflet-pane
      z-index: 1
    .leaflet-top, .leaflet-bottom, .leaflet-control
      z-index: 2
    &.leaflet-container:after
      z-index: 3

@media #{$breakpoint-larger-than-sm-768px}
  .bloc.bloc-carte-producteurs
    .producer-map
      height: 800px

      .leaflet-control-layers
        // display controls on desktop and tablets
        display: initial
